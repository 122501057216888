import React from "react";
import { Modal, ModalBody, ModalFooter } from "nice-react-modal";
import { useFormik } from "formik";
import toggleQuestionTrend from "../../api/question/toggleQuestionTrend";
import Loader from "../common/Loader";
// import acceptReqst from "../../api/consulters-reqsts/accpetReqst";

export default function ToggleTrendingQuestionModal({
  questionId,
  isTrending,
  isOpen,
  closeModal,
}) {
  let formik = useFormik({
    initialValues: {},
    onSubmit: async ({}, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await toggleQuestionTrend(questionId, isTrending);

        window.location.reload();
      } catch (err) {
        console.log(err);
      }
      setSubmitting(false);
    },
  });

  return (
    <Modal onClose={closeModal}>
      <ModalBody>
        <form onSubmit={formik.handleSubmit} id={`trendingQuestion-${questionId}`}>
          {!isTrending ? (
            <p>هل أنت متأكد أنك تريد وضع السؤال كسؤال شائع ؟</p>
          ) : (
            <p>
              هل أنت متأكد أنك تريد إزالة هذا السؤال من قائمة الأسئلة الشائعة ؟
            </p>
          )}
        </form>
      </ModalBody>

      <ModalFooter>
        <div className="flex items-center justify-between w-full">
          {formik.isSubmitting ? (
            <span className="bg-green-400 hover:bg-green-500 text-white px-10 py-2 rounded-full">
              <Loader className="mx-auto" />
            </span>
          ) : (
            <button
              type="submit"
              form={`trendingQuestion-${questionId}`}
              className="bg-green-400 hover:bg-green-500 text-white px-10 py-2 rounded-full"
            >
              تأكيد
            </button>
          )}
          <span
            className="bg-gray-200 cursor-pointer hover:bg-gray-300 text-gray-600 px-10 py-2 shadow rounded-full mx-2 text-center"
            onClick={closeModal}
          >
            أغلق
          </span>
        </div>
      </ModalFooter>
    </Modal>
  );
}
