import React from "react";
// import Modal from "../common/Modal";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "nice-react-modal";
import { useFormik } from "formik";
import axios from "./../../config/axios";
import Cookies from "js-cookie";

export default function ConfirmUserAccountModal({ user, isOpen, closeModal }) {
  // user/give/confirm-account

  let formik = useFormik({
    initialValues: {},

    onSubmit: async () => {
      try {
        let res = await axios().post("/user/give/confirm-account", {
          user_id: user.id,
        });

        if (res.status === 200) {
          Cookies.set("success", res.data, {
            expires: new Date(new Date().getTime() + 15 * 1000),
          });

          window.location.reload();
        }
      } catch (err) {
        alert("Something went wrong");
      }
    },
  });

  return (
    <Modal onClose={closeModal}>
      <ModalTitle>تأكيد حساب المستخدم</ModalTitle>
      <ModalBody>
        <p class="py-2 font-semibold text-gray-700">
          هل أنت متأكد أنك تريد تأكيد حساب هذا المستخدم "{user.name}"؟
        </p>
        <form id={`confirmUser-${user.id}`} onSubmit={formik.handleSubmit}></form>
      </ModalBody>

      <ModalFooter>
        <button
          type="submit"
          form={`confirmUser-${user.id}`}
          class="inline-block shadow bg-green-500 p-2 px-3 rounded text-white font-semibold focus:outline-none hover:bg-green-600"
        >
          تأكيد الحساب
        </button>

        <button
          onclick={closeModal}
          type="button"
          class="bg-gray-200 py-2 px-3 rounded text-gray-600 font-semibold focus:outline-none hover:bg-gray-300"
        >
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}
