import React from "react";
// import Modal from '../common/Modal';
import { Modal, ModalBody, ModalFooter, ModalTitle } from "nice-react-modal";
import { useFormik } from "formik";
import axios from "../../config/axios";
import Cookies from "js-cookie";

export default function GiveSpecialBadgeModal({ user, isOpen, closeModal }) {
  // user/give/special-badge

  let formik = useFormik({
    initialValues: {},

    onSubmit: async () => {
      try {
        let res = await axios().post("/user/give/special-badge", {
          user_id: user.id,
        });
        if (res.status === 200) {
          Cookies.set("success", res.data, {
            expires: new Date(new Date().getTime() + 15 * 1000),
          });

          window.location.reload();
        }
      } catch (err) {
        alert("Something went wrong");
      }
    },
  });

  return (
    <Modal title="" isOpen={isOpen} onClose={closeModal}>
      <ModalTitle>إعطاء شارة خاصة</ModalTitle>
      <ModalBody>
        <div>
          <p className="py-2 text-gray-700 block whitespace-pre-wrap">
            هل أنت متأكد أنك تريد {user.special_badge == 1 ? "نزع" : "منح"} هذا
            المستخدم "<b>{user.fullname}</b>" شارة خاصة؟ تسمح هذه الشارة لهذا
            المستخدم بوضع إجاباته في الأعلى بغض النظر عن أي شيء
          </p>
        </div>

        <form
          id={`giveSpecialBadge-${user.id}`}
          onSubmit={formik.handleSubmit}
        ></form>
      </ModalBody>

      <ModalFooter>
        {user.special_badge == 1 ? (
          <button
            type="submit"
            form={`giveSpecialBadge-${user.id}`}
            class="inline-block bg-red-600 py-2 px-5 rounded-full text-white font-semibold focus:outline-none hover:bg-red-700"
          >
            نزع
          </button>
        ) : (
          <button
            type="submit"
            form={`giveSpecialBadge-${user.id}`}
            class="inline-block bg-blue-600 py-2 px-5 rounded-full text-white font-semibold focus:outline-none hover:bg-blue-700"
          >
            منح
          </button>
        )}

        <button
          onClick={closeModal}
          type="button"
          class="inline-block bg-gray-200 py-2 px-5 rounded-full text-gray-600 font-semibold focus:outline-none hover:bg-gray-300"
        >
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}
