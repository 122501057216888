import axios from '../../config/axios'

export default async function toggleQuestionTrend(questionId, isTrending){
   try{
      // console.log(data)
      await axios().post(`/question/trending`, {questionId, isTrending});

   }catch(err){
     throw new Error(err);
   }
}
