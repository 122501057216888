import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/tailwind.css";
import "./assets/css/custom.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { X } from "react-feather";

ReactDOM.render(
  <React.Fragment>
    <ToastContainer
      hideProgressBar
      rtl
      closeButton={<X size={20} />}
      position="top-center"
    />
    <App />
  </React.Fragment>,
  document.getElementById("root")
);
