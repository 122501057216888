import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "nice-react-modal";
import { useFormik } from "formik";
import axios from "../../config/axios";
import { toast } from "react-toastify";

export default function AcceptUserAccountModal({ userId, isOpen, closeModal }) {
  let [isSending, setIsSending] = useState(false);

  let formik = useFormik({
    initialValues: {},

    onSubmit: async () => {
      setIsSending(true);
      try {
        let res = await axios().post("/user/give/accept", { userId: userId });
        if (res.status === 200) {
          localStorage.setItem("success", true);

          window.location.reload();
        }
      } catch (err) {
        toast(
          "Something went wrong, please refresh the browser and try again",
          {
            className: "infoToast",
          }
        );
      }

      setIsSending(false);
    },
  });

  return (
    <Modal onClose={closeModal}>
      <ModalBody>
        <p className="whitespace-pre-wrap">
        قبول حساب المستخدم؟
        </p>
        <form onSubmit={formik.handleSubmit} id={`acceptAcc-${userId}`}></form>
      </ModalBody>

      <ModalFooter>
        {isSending ? (
          <button
            type="button"
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full"
          >
            جارٍ قبول الحساب ...
          </button>
        ) : (
          <button
            type="submit"
            form={`acceptAcc-${userId}`}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full"
          >
            قبول الحساب
          </button>
        )}
        <button
          onClick={closeModal}
          type="button"
          className="bg-gray-200 p-2 px-3 rounded-full text-gray-600 font-semibold focus:outline-none hover:bg-gray-300"
        >
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}
