import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function QuillEditor({ onChange, value }) {

   // let [value, setValue] = useState();

   let modules = {
      toolbar: [
         // [{ 'header': [1, 2, false] }],
         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
         ['code-block'],
         [{ 'direction': 'rtl' }],
         [{ 'list': 'ordered' }, { 'list': 'bullet' }],
         ['link'],
         ['clean']
      ],

      clipboard: {
         matchVisual: false
      }
      
   }

   return (
      <div className='text-right'>
         <ReactQuill
            theme="snow"
            preserveWhitespace={true}
            placeholder='اكتب اجابتك...'
            onChange={onChange}
            value={value}
            modules={modules}
         />
      </div>
   );

}
