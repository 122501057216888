import React, { useState, useEffect } from 'react'

export default function Popup({msg, type}) {
   
   // let popup_action = !action ? 'openit' : action;

   let [isOpen, setIsOpen] = useState(true)

   useEffect(() => {
    
      let tout = setTimeout(() => {
         setIsOpen(false)
      }, 4000);
   
      // clearTimeout(tout)
   }, [])
   
   return (
      <div>
         <div id='poopup' class={`poopup ${type} ${isOpen ? 'openit' : null} text-center w-full fixed left-0 top-0 py-3 px-2`} style={{zIndex: '99999'}}>
            {msg}
         </div>
      </div>
   )
}
