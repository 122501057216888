import React, { useState } from "react";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "nice-react-modal";
import { useFormik } from "formik";
import axios from "./../../config/axios";
import Cookies from "js-cookie";

export default function DeleteTopicModal({ topic, isOpen, closeModal }) {
  let formik = useFormik({
    onSubmit: async () => {
      let res = await axios().post("/topic/delete", {
        id: topic.id,
      });

      if (res.status === 200) {
        Cookies.set("success", res.data, {
          expires: new Date(new Date().getTime() + 15 * 1000),
        });

        window.location.reload();
      }
    },
  });

  return (
    <Modal onClose={closeModal}>
      <ModalTitle>حذف الموضوع</ModalTitle>
      <ModalBody>
        <p>هل أنت متأكد أنك تريد حذف موضوع "{topic.name}" ؟</p>
        <form
          onSubmit={formik.handleSubmit}
          id={`deleteTopic-${topic.id}`}
        ></form>
      </ModalBody>

      <ModalFooter>
        <button
          type="submit"
          form={`deleteTopic-${topic.id}`}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full"
        >
          حذف
        </button>
        <button
          onClick={closeModal}
          type="button"
          className="inline-block bg-gray-200 p-2 px-3 rounded-full text-gray-600 font-semibold focus:outline-none hover:bg-gray-300"
        >
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}
