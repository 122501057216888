import React, { useContext, useState } from 'react';
import AdminContext from '../../config/AdminContext';
import Loader from '../common/Loader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from '../../config/axios';
import Cookies from 'js-cookie';

export default function NameTab({ isOpen }) {
   let loggedInAdmin = useContext(AdminContext);

   let formik = useFormik({
      initialValues: {
         name: loggedInAdmin.admin.name
      },

      validateOnBlur: true,
      validationSchema: Yup.object().shape({
         name: Yup.string()
            .required('يرجى كتابة اسمك')
            .max(30, 'الاسم طويل جدًا ، حاول جعله أقصر')
      }),

      onSubmit: async ({ name }) => {
         try {

            let res = await axios().post('/setting/name', {
               name
            });

            if (res.status === 200) {
               Cookies.set('success', res.data, {
                  expires: new Date(new Date().getTime() + 15 * 1000)
               });

               window.location.reload()
            } else if (res.status === 202) {
               formik.setErrors({ name: res.data })
            }

         } catch (err) {
            formik.setErrors({ name: "حدث خطأ ما ، يرجى المحاولة مرة أخرى لاحقًا" })
         }

      }
   })


   return (
      <div
         className={`tab shadow bg-white p-4 py-10 border-r md:border-t border-gray-300 ${!isOpen ? 'hidden' : ''}`}
      >
         <form onSubmit={formik.handleSubmit}>
            <input
               className={`bg-gray-100 appearance-none border-2 border-gray-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 ${formik.errors.name ? 'border-red-400' : ''}`}
               placeholder='اكتب اسمك'
               name="name"
               type="text"
               defaultValue={formik.values.name}
               onChange={formik.handleChange}
            />
            <p className="text-red-500 text-xs italic mt-1">
               {formik.errors.name}
            </p>
            {
               formik.isSubmitting ? (
                  <span className="mt-10 block text-center shadow bg-blue-500 hover:bg-blue-600 opacity-50 text-white font-bold py-2 px-4 rounded w-full">
                     <Loader className='mx-auto' />
                  </span>
               ) : (
                     formik.values.name !== '' && !formik.errors.name ? (
                        <button
                           type="submit"
                           className="mt-10 shadow bg-blue-500 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded w-full"
                        >
                           حفظ التغييرات
                        </button>
                     ) : (
                           <span
                              className="mt-10 block text-center shadow bg-blue-500 hover:bg-blue-600 opacity-50 text-white font-bold py-2 px-4 rounded w-full"
                           >
                              حفظ التغييرات
                        </span>
                        )
                  )
            }

         </form>
      </div>
   );
}
