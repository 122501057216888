import React, { useState } from "react";
// import Modal from '../common/Modal';
import { Modal, ModalBody, ModalFooter } from "nice-react-modal";
import { useFormik } from "formik";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

export default function DeleteQuestionModal({ questionId, questionTitle, isOpen, closeModal }) {
  let [isSending, setIsSending] = useState(false);

  let formik = useFormik({
    initialValues: {},

    onSubmit: async () => {
      setIsSending(true);
      try {
        let res = await axios().post("/question/delete", {
          id: questionId,
        });
        if (res.status === 200) {
          Cookies.set("success", res.data, {
            expires: new Date(new Date().getTime() + 15 * 1000),
          });

          window.location.reload();
        }
      } catch (err) {
        toast(
          "Something went wrong, please refresh the browser and try again",
          {
            className: "infoToast",
          }
        );
      }

      setIsSending(false);
    },
  });
  // const formId = Math.floor(Math.random() * (9999 * questionId));

  return (
    <Modal onClose={closeModal}>
      <ModalBody>
        <p className="whitespace-pre-wrap">
          هل تريد بالتأكيد حذف هذا السؤال
          <b className="whitespace-pre-wrap"> "{questionTitle}" </b>
        </p>
        <form onSubmit={formik.handleSubmit} id={`deleteQuestion-${questionId}`}></form>
      </ModalBody>

      <ModalFooter>
        {isSending ? (
          <button
            type="button"
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full"
          >
            جارٍ الحذف ...
          </button>
        ) : (
          <button
            type="submit"
            form={`deleteQuestion-${questionId}`}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full"
          >
            حذف
          </button>
        )}
        <button
          onClick={closeModal}
          type="button"
          className="bg-gray-200 p-2 px-3 rounded-full text-gray-600 font-semibold focus:outline-none hover:bg-gray-300"
        >
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}
