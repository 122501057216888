import axios from 'axios';
import config from './config';
import conig from './config';
// import Cookies from 'js-cookie'

function axiosFn(usePureServer){
   const instance = axios.create({
      baseURL: usePureServer ? config.pure_server_url : conig.server_url,
      withCredentials: true,
      // headers: {
      //    'X-CSRF-TOKEN': Cookies.get('csrfToken')
      // }
   });

   return instance;
}

export default axiosFn;
