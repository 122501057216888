import React from "react";
import Dropzone from "react-dropzone";
import { Upload } from "react-feather";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import Loader from "../../components/common/Loader";
import create from "../../api/topic/create";

export default function CreateTopic() {
  let submitForm = async (name, content, status, topicImg) => {
    const data = new FormData();
    data.append("name", name);
    data.append("content", content);
    data.append("status", status);
    // if user uploaded a topicImg
    if (topicImg.length > 0) {
      data.append("topicImg", topicImg[0], topicImg[0].name);
    }

    await create(data);
  };

  const schema = yup.object().shape({
    name: yup.string().required("الرجاء إدخال اسم الموضوع"),
    content: yup.string().required("الرجاء كتابة محتوى الموضوع"),
    topicImg: yup.array().required("قم بتحميل الشعار"),
  });

  let formik = useFormik({
    initialValues: {
      name: "",
      content: "",
      status: 1,
      topicImg: [],
    },

    validationSchema: schema,

    onSubmit: async ({ name, content, status, topicImg }, { resetForm }) => {
      await submitForm(name, content, status, topicImg);
      resetForm();
    },
  });

  return (
    <div>
      <div className="mx-auto max-w-2xl mt-5 mb-20 p-5 bg-white rounded shadow-md">
        <h2 className="mb-10 text-center text-xl text-gray-700">
          إنشاء موضوع جديد
        </h2>
        <form onSubmit={formik.handleSubmit} enctype="multipart/form-data">
          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              اسم الموضوع
            </label>
            <input
              className={`shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formik.errors.name ? "border-red-400" : ""
              }`}
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="مثال:  مشاكل تقنية"
            />
            {formik.errors.name ? (
              <p className="text-red-400 text-sm">{formik.errors.name}</p>
            ) : null}
          </div>
          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              محتوى الموضوع
            </label>
            <textarea
              className={`shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formik.errors.content ? "border-red-400" : ""
              }`}
              type="text"
              name="content"
              value={formik.values.content}
              onChange={formik.handleChange}
              placeholder="اكتب هنا"
            />
            {formik.errors.content ? (
              <p className="text-red-400 text-sm">{formik.errors.content}</p>
            ) : null}
          </div>
          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              الظهور على الصفحة الرئيسية
            </label>
            <select
              name="status"
              onChange={formik.handleChange}
              className="shadow-sm bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="1">ظاهر على الصفحة الرئيسية</option>
              <option value="0">مخفي من الصفحة الرئيسية</option>
            </select>
          </div>

          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              شعار الموضوع
            </label>
            <div
              className={`hover:bg-gray-200 relative h-32 w-full rounded overflow-hidden mb-5 user-img-edit border-2 border-gray-300 transition cursor-pointer ${
                formik.errors.topicImg ? "border-red-400" : ""
              }`}
            >
              <Upload
                className="absolute text-gray-600 mx-auto"
                style={{ top: "40%", left: "45%" }}
              />
              <Dropzone
                accept="image/jpeg, image/jpg, image/png"
                multiple={false}
                maxSize={1000000} // 1000000 = 1 MB
                onDrop={(file) => {
                  formik.setFieldValue("topicImg", file);
                }}
                onDropRejected={(err) => {
                  if (err[0].errors[0].code === "file-too-large") {
                    toast("الصورة كبيرة جدًا ، يجب ألا تتجاوز 1 ميغابايت", {
                      className: "errorToast",
                    });
                  } else {
                    toast(
                      "يرجى تحميل صورة ، يجب أن تكون من نوع jpeg أو png أو jpg",
                      { className: "errorToast" }
                    );
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="h-full block">
                    <input
                      {...getInputProps()}
                      className="w-full bg-gray-300 z-50 relative h-full opacity-0 cursor-pointer"
                      style={{ display: "block" }}
                    />
                  </div>
                )}
              </Dropzone>
            </div>

            {formik.errors.topicImg ? (
              <p className="text-red-400 text-sm">{formik.errors.topicImg}</p>
            ) : null}
          </div>

          {formik.isSubmitting ? (
            <span className="opacity-50 block w-full block bg-blue-500 text-white p-2 rounded shadow mt-16">
              <Loader className="mx-auto" />
            </span>
          ) : (
            <button
              type="submit"
              className="w-full block bg-purple-600 text-white p-2 rounded shadow mt-16 hover:bg-purple-700"
            >
              أضف الموضوع
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
