import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import * as Yup from "yup";
import axios from "../../config/axios";
import Loader from "../../components/common/Loader";
import { toast } from "react-toastify";
import QuillEditor from "../../components/editor/QuillEditor";

export default function AddQuestion() {
  let [topics, setTopics] = useState([]);
  let [loadingTopics, setLoadingTopics] = useState(true);
  let [loadingCreation, setLoadingCreation] = useState(false);
  let MAX_CHARS = 200;

  // Get topics
  useEffect(() => {
    (async function () {
      try {
        const topics = await axios(true).get("/topics/all");

        setTopics(topics.data);
      } catch (e) {
        setTopics([]);
      }
      setLoadingTopics(false);
    })();
  }, []);

  const topicsOptions = [];
  if (!loadingTopics) {
    topics.forEach((topic) => {
      topicsOptions.push({ value: topic.id, label: topic.name });
    });
  }

  let formik = useFormik({
    initialValues: {
      question: "",
      topics: [],
      desc: "",
    },
    // validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      question: Yup.string()
        .trim()
        .min(10, "السؤال قصير للغاية ، حاول أن تجعله أكثر وضوحًا")
        .max(MAX_CHARS, "سؤالك طويل جدا ، يرجى المحاولة لجعله أقصر")
        .required("يرجى كتابة سؤالك"),
      desc: Yup.string().required("صف مشكلتك من فضلك."),
      topics: Yup.array()
        .min(1, "يرجى اختيار موضوع على الأقل")
        .max(3, "لا يمكنك اختيار أكثر من 3 مواضيع")
        .nullable()
        .of(
          Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
        ),
    }),
    // { question, points, topics }
    onSubmit: async ({ question, topics, desc }) => {
      // console.log(values);
      setLoadingCreation(true);
      try {
        let createQuestRes = await axios().post("/add-question", {
          title: question,
          topics,
          desc,
        });
        // console.log('createQuestRes', createQuestRes)
        if (createQuestRes.status === 200) {
          window.location.reload();
        } else if (createQuestRes.status === 202) {
          toast(createQuestRes.data.error, { className: "errorToast" });
        }
      } catch (err) {
        if (err.response.status === 429) {
          toast("لقد قمت بإنشاء الكثير من الأسئلة ، يرجى العودة لاحقًا", {
            className: "errorToast",
          });
        } else {
          toast("يبدو أن هناك خطأ ما ، نحن نعمل بجد لإصلاحه", {
            className: "warnToast",
          });
        }
      }

      setLoadingCreation(false);
    },
  });

  let questionCharsInPerc =
    87.5 - formik.values.question.length / ((MAX_CHARS + 1) / 87.5);

  return (
    <div className="max-w-screen-md mx-auto bg-white shadow p-5 rounded-lg">
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-10">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            اكتب سؤالك
          </label>
          <textarea
            className="appearance-none border-2 rounded w-full py-4 px-3 text-black leading-tight focus:outline-none border-gray-300 focus:border-blue-400"
            onChange={formik.handleChange}
            value={formik.values.question}
            name="question"
            type="text"
            placeholder='ابدأ سؤالك بـ "ماذا" ، "كيف" ، إلخ ...'
            rows="3"
          ></textarea>
          <div className="flex items-center justify-between mt-2">
            <p className="text-red-500 text-sm">{formik.errors.question}</p>
            {/* 200/<span id="usedChars">{formik.values.question.length}</span> */}
            <div className="relative w-8 h-8">
              <svg
                height="100%"
                viewBox="0 0 30 30"
                width="100%"
                style={{ overflow: "visible" }}
              >
                <circle
                  cx="50%"
                  cy="50%"
                  fill="none"
                  stroke-width="2"
                  r="14"
                  stroke="#e2e8f0"
                ></circle>
                <circle
                  cx="50%"
                  cy="50%"
                  fill="none"
                  stroke-width="2"
                  r="14"
                  stroke={`${
                    questionCharsInPerc > 30
                      ? "#1da1f2"
                      : questionCharsInPerc > 0 && questionCharsInPerc <= 30
                      ? "#FFAD1F"
                      : "#ff3d7f"
                  }`}
                  stroke-linecap="round"
                  style={{
                    strokeDashoffset: `${
                      questionCharsInPerc <= 0 ? 0 : questionCharsInPerc
                    }`,
                    strokeDasharray: 87.5,
                  }}
                  data-darkreader-inline-fill=""
                  data-darkreader-inline-stroke=""
                ></circle>
              </svg>
              <span
                className={`absolute left-0 right-0 text-center text-xs ltr-dir ${
                  questionCharsInPerc > 30
                    ? "text-gray-700"
                    : questionCharsInPerc > 0 && questionCharsInPerc <= 30
                    ? "text-orange-500"
                    : "text-red-500"
                }`}
                style={{ top: "8px" }}
              >
                {MAX_CHARS - formik.values.question.length}
              </span>
            </div>
          </div>
        </div>
        <div className="mb-10">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="grid-first-name"
          >
            صف مشكلتك
          </label>
          <QuillEditor
            onChange={(val) => formik.setFieldValue("desc", val)}
            value={formik.values.desc}
          />
          <p className="text-red-500 text-sm">{formik.errors.desc}</p>
        </div>
        <div className="px-3 ">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            تصنيفك الصحيح لسؤالك يسهل الحصول على إجابتك
          </label>
          <div className="block relative">
            <Select
              isLoading={loadingTopics ? true : false}
              components={makeAnimated()}
              placeholder="اختر الموضوع"
              loadingMessage={() => <Loader className="my-5" />}
              isMulti={false}
              isSearchable
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#8e62a71f",
                  primary: "#8e62a7",
                },
              })}
              name="topics"
              isRtl={true}
              options={loadingTopics ? [] : topicsOptions}
              noOptionsMessage={() => "لا مزيد من المواضيع"}
              onChange={(v) => {
                formik.setFieldValue("topics", v);
                // if (v == null || v.length < 4) {
                //   formik.setFieldValue("topics", v);
                // }
              }}
              value={formik.values.topics}
            />
          </div>
          <p className="text-red-500 text-sm mt-2">{formik.errors.topics}</p>
          <div id="topicsTags" className="flex flex-wrap items-center mt-3" />
        </div>

        {loadingCreation ? (
          <button
            type="button"
            className="opacity-50 block mx-auto shadow rounded bg-purple-600 px-20 py-3 text-white font-bold mt-16"
          >
            <Loader className="mx-auto" />
          </button>
        ) : (
          <button
            type="submit"
            className="block mx-auto shadow rounded bg-purple-600 hover:bg-purple-700 px-20 py-3 text-white font-bold mt-16"
          >
            انشر سؤالك
          </button>
        )}
      </form>
    </div>
  );
}
