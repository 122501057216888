// resolves the image path, and returns the working img

import config from '../config/config';

export default function resolveImg(image) {
   let url = '';

   if (image.indexOf('storage') === 1) {
      const img = image[0] === '/' ? image.slice(1) : image;   
      url = `${config.pure_server_url}/${img}`;
   } else {
      url = `${image}`;
   }

   return url;
}
