import React from "react";
import { Award, CheckCircle, Plus, Shield, Trash } from "react-feather";

export default function UserActionsDropdown({
  onClickDeleteUser,
  onClickGiveSpecialBadge,
  onClickConfirmAccount,
  onClickAddPoints,
  onClickAddManagerBadge,
  isUserVerified,
}) {
  return (
    <div className="absolute top-full z-30 left-0 w-40 bg-white shadow-md rounded-md p-1 overflow-y-auto">
      <p
        className="flex items-center text-xs p-2 cursor-pointer hover:bg-gray-100 rounded"
        onClick={onClickDeleteUser}
      >
        <Trash size={15} className="ml-2 text-gray-500" />
        حذف المستخدم
      </p>

      <p
        className="flex items-center text-xs p-2 mt-2 cursor-pointer hover:bg-gray-100 rounded"
        onClick={onClickGiveSpecialBadge}
      >
        <Award size={15} className="ml-2 text-gray-500" />
        شارة المستخدم الخاصة
      </p>
      <p
        className="flex items-center text-xs p-2 mt-2 cursor-pointer hover:bg-gray-100 rounded"
        onClick={onClickAddPoints}
      >
        <Plus size={15} className="ml-2 text-gray-500" />
        اعطاء نقاط
      </p>
      <p
        className="flex items-center text-xs p-2 mt-2 cursor-pointer hover:bg-gray-100 rounded"
        onClick={onClickAddManagerBadge}
      >
        <Shield size={15} className="ml-2 text-gray-500" />
        شارات موظفي المجتمع
      </p>

      {!isUserVerified ? (
        <p
          className="flex items-center text-xs p-2 mt-2 cursor-pointer hover:bg-gray-100 rounded"
          onClick={onClickConfirmAccount}
        >
          <CheckCircle size={18} className="ml-2 text-gray-500" />
          تأكيد الحساب
        </p>
      ) : null}
    </div>
  );
}
