const Configs = function () {
  // this.env = "DEV";

  // this.env= 'PROD';

  this.port = "7899";

  this.pure_server_url =
    process.env.NODE_ENV == "development"
      ? `http://localhost:${this.port}`
      : "https://fcapi.tsaol.net";

      
  this.server_url =
    process.env.NODE_ENV == "development"
      ? `http://localhost:${this.port}/admin`
      : "https://fcapi.tsaol.net/admin";
};

let config = new Configs();
export default config;
