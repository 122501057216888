import React, { useState } from "react";
// import Modal from './../common/Modal';
import { Modal, ModalBody, ModalFooter, ModalTitle } from "nice-react-modal";
import { useFormik } from "formik";
import axios from "./../../config/axios";
import Cookies from "js-cookie";

export default function DeleteUserModal({ user, isOpen, closeModal }) {
  let [isSending, setIsSending] = useState(false);

  let formik = useFormik({
    initialValues: {},

    onSubmit: async () => {
      setIsSending(true);
      try {
        let res = await axios().post("/user/delete", {
          id: user.id,
        });
        if (res.status === 200) {
          Cookies.set("success", res.data, {
            expires: new Date(new Date().getTime() + 15 * 1000),
          });

          window.location.reload();
        }
      } catch (err) {
        alert("Something went wrong, please refresh the browser and try again");
      }

      setIsSending(false);
    },
  });

  return (
    <Modal onClose={closeModal}>
      <ModalTitle>حذف المستخدم</ModalTitle>
      <ModalBody>
        <p>
          هل أنت متأكد أنك تريد حذف المستخدم "<b>{user.fullname}</b>" ؟
        </p>
        <form
          onSubmit={formik.handleSubmit}
          id={`deleteUser-${user.id}`}
        ></form>
      </ModalBody>

      <ModalFooter>
        <div className="flex items-center justify-between w-full">
          {isSending ? (
            <button
              type="button"
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full"
            >
              جارٍ الحذف ...
            </button>
          ) : (
            <button
              type="submit"
              form={`deleteUser-${user.id}`}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full"
            >
              حذف
            </button>
          )}
          <span
            onClick={closeModal}
            className="bg-gray-200 cursor-pointer py-2 px-4 rounded-full text-gray-600 font-semibold focus:outline-none hover:bg-gray-300"
          >
            إلغاء
          </span>
        </div>
      </ModalFooter>
    </Modal>
  );
}
