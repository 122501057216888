import React, { useState } from "react";
// import Modal from '../common/Modal';
import { Modal, ModalBody, ModalFooter } from "nice-react-modal";
import { useFormik } from "formik";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

export default function DeleteReportModal({ reportId, closeModal }) {
  let [isSending, setIsSending] = useState(false);

  let formik = useFormik({
    initialValues: {},

    onSubmit: async () => {
      setIsSending(true);
      try {
        await axios().post("/report/delete", { reportId });

        toast("تم حذف الإبلاغ", { className: "successToast" });

        window.location.reload();
      } catch (err) {
        toast(
          "Something went wrong, please refresh the browser and try again",
          {
            className: "infoToast",
          }
        );
      }

      setIsSending(false);
    },
  });

  return (
    <Modal onClose={closeModal}>
      <ModalBody>
        <p className="whitespace-pre-wrap">
          هل تريد بالتأكيد حذف هذا الإبلاغ
        </p>
        <form
          onSubmit={formik.handleSubmit}
          id={`deleteReport-${reportId}`}
        ></form>
      </ModalBody>

      <ModalFooter>
        {isSending ? (
          <button
            type="button"
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full"
          >
            جارٍ الحذف ...
          </button>
        ) : (
          <button
            type="submit"
            form={`deleteReport-${reportId}`}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-full"
          >
            حذف
          </button>
        )}
        <button
          onClick={closeModal}
          type="button"
          className="bg-gray-200 p-2 px-3 rounded-full text-gray-600 font-semibold focus:outline-none hover:bg-gray-300"
        >
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}
