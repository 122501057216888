import React from "react";
import Dropzone from "react-dropzone";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "nice-react-modal";
import { Upload } from "react-feather";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import Loader from "../common/Loader";
import edit from "../../api/topic/edit";

export default function EditTopicModal({
  topicName,
  topicContent,
  topicStatus,
  topicId,
  isOpen,
  closeModal,
}) {
  let submitForm = async (name, content, status, topicImg) => {
    const data = new FormData();
    data.append("name", name);
    data.append("content", content);
    data.append("status", status);
    data.append("topicId", topicId);

    // if user uploaded an topicImg
    if (topicImg.length > 0) {
      data.append("topicImg", topicImg[0], topicImg[0].name);
    }

    await edit(data);
  };

  let formik = useFormik({
    initialValues: {
      name: topicName,
      content: topicContent,
      status: topicStatus,
      topicImg: [],
    },

    validationSchema: yup.object().shape({
      name: yup.string().required("الرجاء إدخال اسم الموضوع"),
      content: yup.string().required("الرجاء كتابة المحتوى"),
    }),

    onSubmit: async ({ name, content, status, topicImg }, { resetForm }) => {
      try {
        await submitForm(name, content, status, topicImg);
        toast("تم تحديث الموضوع بنجاح", { className: "successToast" });
        window.location.reload();
      } catch (err) {
        console.log(err);
        toast("هناك خطأ ما...", { className: "warnToast" });
      }
    },
  });
  return (
    <Modal onClose={closeModal}>
      <ModalTitle>تحديث الموضوع</ModalTitle>
      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
          enctype="multipart/form-data"
          id={`editTopic-${topicId}`}
        >
          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              اسم الموضوع
            </label>
            <input
              className={`border border-gray-200 focus:border-gray-400 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formik.errors.name ? "border-red-400" : ""
              }`}
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="مثال:  مشاكل تقنية"
            />
            {formik.errors.name ? (
              <p className="text-red-400 text-sm">{formik.errors.name}</p>
            ) : null}
          </div>
          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              محتوى الموضوع
            </label>
            <textarea
              className={`shadow-sm appearance-none border focus:border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formik.errors.content ? "border-red-400" : ""
              }`}
              type="text"
              name="content"
              value={formik.values.content}
              onChange={formik.handleChange}
              placeholder="اكتب هنا"
            />
            {formik.errors.content ? (
              <p className="text-red-400 text-sm">{formik.errors.content}</p>
            ) : null}
          </div>
          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              الظهور على الصفحة الرئيسية
            </label>
            <select
              name="status"
              onChange={formik.handleChange}
              className="shadow-sm bg-white border focus:border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="1" selected={topicStatus === 1}>ظاهر على الصفحة الرئيسية</option>
              <option value="0" selected={topicStatus === 0}>مخفي من الصفحة الرئيسية</option>
            </select>
          </div>

          <div className="mb-10">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              شعار الموضوع
            </label>
            <div className="hover:bg-gray-200 relative h-32 w-32 rounded overflow-hidden mb-5 user-img-edit border-2 border-gray-300 transition cursor-pointer">
              <Upload
                className="absolute text-gray-600"
                style={{ top: "40%", left: "40%" }}
              />
              <Dropzone
                accept="image/jpeg, image/jpg, image/png, image/svg+xml"
                multiple={false}
                maxSize={1000000} // 1000000 = 1 MB
                onDrop={(file) => {
                  formik.setFieldValue("topicImg", file);
                }}
                onDropRejected={(err) => {
                  if (err[0].errors[0].code === "file-too-large") {
                    toast("الصورة كبيرة جدًا ، يجب ألا تتجاوز 1 ميغابايت", {
                      className: "errorToast",
                    });
                  } else {
                    toast(
                      "يرجى تحميل صورة ، يجب أن تكون من نوع jpeg أو png أو jpg",
                      { className: "errorToast" }
                    );
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="h-full block">
                    <input
                      {...getInputProps()}
                      className="w-full bg-gray-300 z-50 relative h-full opacity-0 cursor-pointer"
                      style={{ display: "block" }}
                    />
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </form>
      </ModalBody>

      <ModalFooter>
        {formik.values.name === topicName && !formik.values.topicImg ? (
          <span className="w-full block bg-green-500 text-white p-2 rounded-full opacity-40 text-center">
            احفظ التغييرات
          </span>
        ) : formik.isSubmitting ? (
          <span className="opacity-50 w-full block bg-green-500 text-white p-2 rounded-full">
            <Loader className="mx-auto" />
          </span>
        ) : (
          <button
            type="submit"
            form={`editTopic-${topicId}`}
            className="w-full block bg-green-500 text-white p-2 rounded-full"
          >
            احفظ التغييرات
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
}
