import React from 'react'
import { CheckCircle } from 'react-feather';

export default function SuccessMsg({body, title, className}) {
   return (
      <div class={`${className} bg-green-100 border-t-4 border-green-500 rounded-b text-green-900 px-4 py-3 shadow my-2`} role="alert">
         <div class="flex items-center">
            <div class="py-1">
               <CheckCircle className='text-2xl text-green-500 ml-4' />
            </div>
            <div>
               {title ? <p class="font-bold">{title}</p> : null}
               <p class="text-sm">{ body }</p>
            </div>
         </div>
      </div>
   )
}
