import React, { useState } from "react";
import { MoreHorizontal, Trash } from "react-feather";
import { Modal, ModalBody } from "nice-react-modal";
import DeleteReportedContentModal from "../modals/DeleteReportedContentModal";
import DeleteReportModal from "../modals/DeleteReportModal";
import config from "../../config/config";

export default function ReportTableRow({
  reason,
  reportedBy,
  reportId,
  reportedContent,
  question,
  answer,
  comment,
}) {
  console.log(comment)
  const [isDeleteContentModalOpen, setIsDeleteContentModalOpen] = useState(
    false
  );
  const [isDeleteReportModalOpen, setIsDeleteReportModalOpen] = useState(false);
  const [readFullAnswerModal, setReadFullAnswerModal] = useState(false);
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);

  return (
    <tr>
      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        {reportedBy}
      </td>
      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        {reportedContent === "answer" ? (
          <span className="text-sm px-3 py-1 rounded-full bg-blue-100 text-blue-600">
            إجابة
          </span>
        ) : reportedContent === "comment" ? (
          <span className="text-sm px-3 py-1 rounded-full bg-yellow-100 text-yellow-600">
            تعليق
          </span>
        ) : (
          <span className="text-sm px-3 py-1 rounded-full bg-green-100 text-green-600">
            سؤال
          </span>
        )}
      </td>
      <td class="px-6 py-4 border-b border-gray-200 max-w-sm">
        {reportedContent === "comment" ? (
          comment.body
        ) : reportedContent === "answer" ? (
          <div className="overflow-hidden overflow-ellipsis">
            <div
              className="whitespace-nowrap"
              dangerouslySetInnerHTML={{ __html: answer.body }}
            />

            <span
              onClick={() => setReadFullAnswerModal(true)}
              className="text-gray-500 hover:text-gray-700 cursor-pointer"
            >
              اقرأ الإجابة كاملة ...
            </span>
          </div>
        ) : (
          `${question.title} ؟`
        )}
      </td>
      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        {reason}
      </td>

      <td class="p-2">
        <div className="relative">
          <MoreHorizontal
            className={`bg-gray-100 hover:bg-gray-300 hover:text-black cursor-pointer p-1 rounded-full ${
              isActionDropdownOpen ? "bg-blue-100 text-blue-500" : ""
            }`}
            onClick={() => setIsActionDropdownOpen(!isActionDropdownOpen)}
          />
          <div
            className={`absolute top-full left-0 z-30 shadow-md p-2 rounded-lg bg-white ${
              isActionDropdownOpen ? "" : "hidden"
            }`}
          >
            <span
              className="text-xs mb-2 flex items-center whitespace-nowrap bg-red-100 py-1 px-2 cursor-pointer hover:bg-red-200 rounded-full"
              onClick={() => setIsDeleteContentModalOpen(true)}
            >
              <Trash size={15} className="text-red-500" />
              <span className="mr-2 text-red-500">حذف المحتوى</span>
            </span>
            <span
              className="text-xs flex items-center whitespace-nowrap bg-blue-100 py-1 px-2 cursor-pointer hover:bg-blue-200 rounded-full"
              onClick={() => setIsDeleteReportModalOpen(true)}
            >
              <Trash size={15} className="text-blue-500" />
              <span className="mr-2 text-blue-500">حذف الإبلاغ</span>
            </span>
          </div>
        </div>
      </td>

      {isDeleteContentModalOpen && (
        <DeleteReportedContentModal
          answerId={answer.id}
          closeModal={() => setIsDeleteContentModalOpen(false)}
          commentId={comment.id}
          questionId={question.id}
        />
      )}

      {isDeleteReportModalOpen && (
        <DeleteReportModal
          closeModal={() => setIsDeleteReportModalOpen(false)}
          reportId={reportId}
        />
      )}

      {readFullAnswerModal && (
        <Modal size="md" onClose={() => setReadFullAnswerModal(false)}>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: answer.body }} />
          </ModalBody>
        </Modal>
      )}
    </tr>
  );
}
