import React, { useState } from "react";
import timeAgo from "nta";
import { Edit2, Trash } from "react-feather";
import EditTopicModal from "./EditTopicModal";
import resolveImg from "../../helpers/resolveImg";
import DeleteTopicModal from "./DeleteTopicModal";

export default function TopicTableRow({ topic }) {
  let [isEditModalOpen, setIsEditModalOpen] = useState(false);
  let [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <tr>
      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        {topic.name}
      </td>
      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        <img
          src={resolveImg(topic.img)}
          alt="Icon"
          className="border border-gray-200 p-1 rounded-xl w-12 h-12"
        />
      </td>
      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        {timeAgo(topic.created_at)}
      </td>

      <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
        <span
          aria-label="edit-topic"
          className="inline-block bg-blue-100 p-2 cursor-pointer hover:bg-blue-200 rounded-full ml-3"
          onClick={() => setIsEditModalOpen(true)}
        >
          <Edit2 size={18} className="text-blue-500" />
        </span>

        <span
          aria-label="delete-topic"
          className="inline-block bg-red-100 p-2 cursor-pointer hover:bg-red-200 rounded-full"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          <Trash size={18} className="text-red-500" />
        </span>

        {/* Modals */}
        {isEditModalOpen && (
          <EditTopicModal
            isOpen={isEditModalOpen}
            closeModal={() => setIsEditModalOpen(false)}
            topicId={topic.id}
            topicContent={topic.content}
            topicStatus={topic.status}
            topicName={topic.name}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteTopicModal
            isOpen={isDeleteModalOpen}
            closeModal={() => setIsDeleteModalOpen(false)}
            topic={topic}
          />
        )}
      </td>
    </tr>
  );
}
