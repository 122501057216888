import React, { useState } from "react";
import timeAgo from "nta";
import { Trash, TrendingDown, TrendingUp } from "react-feather";
import DeleteQuestionModal from "../modals/DeleteQuestionModal";
import ToggleTrendingQuestionModal from "../modals/ToggleTrendingQuestionModal";

export default function QuestionTableRow({ id, title, userFullname, createdAt, isTrending }) {
  let [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  let [isTrendingModalOpen, setIsTrendingModalOpen] = useState(false);
  // console.log(question)

  return (
    <tr>
      <td class="px-6 py-4 whitespace-wrap border-b border-gray-200">
        {title}
      </td>
      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        {!userFullname ? "لوحة التحكم" : userFullname}
      </td>
      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        {timeAgo(createdAt)}
      </td>

      <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
        <span
          aria-label="delete-topic"
          className="inline-block text-red-500 bg-red-100 p-2 cursor-pointer hover:bg-red-200 rounded-full"
          title="حذف السؤال"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          <Trash size={18} />
        </span>
        <span
          aria-label="delete-topic"
          className={`
              inline-block mr-3 ${
                !isTrending
                  ? "text-green-500 bg-green-100 hover:bg-green-200"
                  : "text-red-500 bg-red-100 hover:bg-red-200"
              } p-2 cursor-pointer rounded-full
            `}
          title="شائع"
          onClick={() => setIsTrendingModalOpen(true)}
        >
          {isTrending ? (
            <TrendingDown size={18} />
          ) : (
            <TrendingUp size={18} />
          )}
        </span>

        {/* Modals */}
        {isDeleteModalOpen && (
          <DeleteQuestionModal
            questionId={id}
            questionTitle={title}
            isOpen={isDeleteModalOpen}
            closeModal={() => setIsDeleteModalOpen(false)}
          />
        )}
        {isTrendingModalOpen && (
          <ToggleTrendingQuestionModal
            questionId={id}
            isTrending={isTrending}
            isOpen={isTrendingModalOpen}
            closeModal={() => setIsTrendingModalOpen(false)}
          />
        )}
      </td>
    </tr>
  );
}
