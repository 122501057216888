import React, { useState } from "react";
// import Modal from '../common/Modal';
import { Modal, ModalBody, ModalFooter, ModalTitle } from "nice-react-modal";
import { useFormik } from "formik";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Loader } from "react-feather";

export default function GiveUserPointsModal({ userId, closeModal }) {
  let [isSending, setIsSending] = useState(false);

  let formik = useFormik({
    initialValues: {
      points: 0,
    },

    onSubmit: async ({ points }) => {
      setIsSending(true);
      try {
        await axios().post("/user/give/points", {
          userId,
          points,
        });
        Cookies.set("success", "تمت إضافة النقاط بنجاح", {
          expires: new Date(new Date().getTime() + 15 * 1000),
        });

        window.location.reload();
      } catch (err) {
        toast("هناك خطأ ما", {
          className: "infoToast",
        });
      }

      setIsSending(false);
    },
  });

  return (
    <Modal onClose={closeModal}>
      <ModalTitle> إعطاء نقاط للمستخدم </ModalTitle>
      <ModalBody>
        <form onSubmit={formik.handleSubmit} id={`givePoints-${userId}`}>
          <input
            type="number"
            placeholder="النقاط التي تريد أن تعطيها"
            name="points"
            onChange={formik.handleChange}
            className="w-full block p-2 rounded-md border border-gray-200"
          />
        </form>
      </ModalBody>

      <ModalFooter>
        {isSending ? (
          <button
            type="button"
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full"
          >
            <Loader size={18} />
          </button>
        ) : (
          <button
            type="submit"
            form={`givePoints-${userId}`}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full"
          >
            أضف نقاط
          </button>
        )}
        <button
          onClick={closeModal}
          type="button"
          className="bg-gray-200 p-2 px-3 rounded-full text-gray-600 font-semibold focus:outline-none hover:bg-gray-300"
        >
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}
